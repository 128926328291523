<template>
  <BaseModal
    name="Modal-Edit-Funil"
    id="Modal-Edit-Funil"
    idModal="Modal-Edit-Funil"
    size="md"
    title="Editar automação"
    @shown="update"
  >
    <div style="display: grid; gap: 20px">
      <div>
        <!-- <label for="funil-name">Digite o tótulo da automação</label> -->
        <b-form-group label="Título da automação">
          <b-form-input
            id="funil-name"
            name="title"
            v-model="title"
            placeholder="Como vai se chamar o funil?"
            v-validate="{ required: true, min: 6, max: 40 }"
          ></b-form-input>

          <b-form-invalid-feedback
            :state="!errors.has('title')"
            class="d-flex flex-column errorName"
          >
            {{
              title.length === 0
                ? ""
                : title.length < 6 || title.length > 40
                ? "Mínimo de 6 caracteres e máximo de 40 caracteres"
                : ""
            }}
            <span class="errorName">{{
              validSpaceTitle ? "Espaços inválidos." : ""
            }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div>
        <div class="add-link-body">
          <div class="label-flex">
            <label>Selecione o formulário da automação</label>
          </div>
          <router-link
            v-if="!all_forms.length && verificUserLevel"
            :to="`/dynamicRoute/funis/${this.$route.params.id}/criar-formulario`"
            class="add-link link mb-0"
            >Deseja criar um formulário?</router-link
          >
        </div>
        <BaseSelect
          id="formulario"
          :selectModel="selected_form"
          placeholder="Selecione um formulário"
          track-by="id"
          :array="all_forms"
          :multiple="false"
          trackname="title"
          :taggable="true"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          :loading="loadingForm"
          @search="debounceForms($event)"
          @callback="getForms($event)"
          @change="selected_form = $event"
          :watch="true"
          :selectEvent="true"
          ><span slot="noResult">Nenhum formulário encontrado.</span>
        </BaseSelect>
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" :disabled="loading" @click="onSubmit">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>

<script>
//
import FunnelService from "@/services/resources/FunnelService";
const serviceFunnel = FunnelService.build();
//
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
export default {
  props: ["selectedFunil"],

  data() {
    return {
      selected_form: { id: null, title: "Selecione um formulário" },
      title: "",
      loading: false,
      stop: false,
      all_forms: [],
      teste: [],
      searchForm: "",
      loadingForm: false,
      stopForm: false,
    };
  },

  computed: {
    validSpaceTitle() {
      const regex = /  /;
      return regex.test(this.title);
    },
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    getForms(page = 1) {
      if (this.stop) {
        return;
      }
      var data = {
        id: `?page=${page}&campaign_id=${this.$route.params.id}&status=active`,
      };

      serviceForm.read(data).then((resp) => {
        this.all_forms = this.all_forms.concat(resp.data);
        if (resp.data.length < resp.per_page) {
          this.stop = true;
        }
      });
    },

    debounceForms: _.debounce(function (query) {
      if (query === "") {
        this.loadingForm = false;
      } else {
        this.searchForm = query;
        this.getForms(this.page, query);
      }
    }, 500),

    getForms(page = 1, query = "") {
      if (this.stopForm == true) {
        this.loadingForm = false;
        return;
      }
      var data = {
        id: `?page=${page}&campaign_id=${this.$route.params.id}&status=active&order_by=title&order=ASC&search=${query}`,
      };
      serviceForm
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopForm = true;
          }
          this.all_forms = this.all_forms.concat(resp.data);
          this.all_forms = this.all_forms.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {});
    },
    onSubmit() {
      if (!this.title) {
        this.$bvToast.toast("Título necessário para automação", {
          title: "Gestão de campanhas",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        return;
      }

      const regex = /  /;
      if (regex.test(this.title)) {
        return;
      }

      if (!this.selected_form || this.selected_form.id == null) {
        this.$bvToast.toast("Formulário necessário para automação", {
          title: "Gestão de campanhas",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        return;
      }
      var data = {
        id: this.selectedFunil.id,
        title: this.title,
        form_id: this.selected_form.id,
      };

      this.$validator.validateAll().then((result) => {
        if (result) {
          serviceFunnel
            .update(data)
            .then((resp) => {
              this.$bvToast.toast("Automação atualizado com sucesso", {
                title: "Gestão de campanhas",
                variant: "info",
                autoHideDelay: 300,
                appendToast: true,
              });
              this.$parent.getFunnel();
              return;
            })
            .catch((error) => {
              this.$bvToast.toast("Erro ao atualizar automação", {
                title: "Gestão de campanhas",
                variant: "danger",
                autoHideDelay: 300,
                appendToast: true,
              });
              return;
            })
            .finally(() => {
              this.$bvModal.hide("Modal-Edit-Funil");
              this.$parent.fetchLead(this.$route.params.id);
            });
        }
      });
    },

    update() {
      this.title = this.selectedFunil.title;
      this.selected_form = this.selectedFunil.forms[0];
      // }
    },
  },
};
</script>

<style scoped lang="scss"></style>

<style scoped lang="scss">
.errorName {
  font-size: 12px;
  font-weight: 500;
  color: #dc3545 !important;
}
</style>

<template>
  <BaseModal
    name="formHtml"
    id="formHtml"
    idModal="formHtml"
    size="lg"
    title="Formulário de integração"
    @shown="buildHtml"
    hideFooter
  >
    <label for="html-code">Código HTML do formulário</label>
    <div style="position: relative;">
      <div class="copy-tag" @click="copyTextArea('html', form_html)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256"><path d="M200,32H163.74a47.92,47.92,0,0,0-71.48,0H56A16,16,0,0,0,40,48V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm-72,0a32,32,0,0,1,32,32H96A32,32,0,0,1,128,32Zm72,184H56V48H82.75A47.93,47.93,0,0,0,80,64v8a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V64a47.93,47.93,0,0,0-2.75-16H200Z"></path></svg>
        Copiar HTML</div>
      <div class="form-code mb-4">
        <code id="html-code">{{ form_html }}</code>
      </div>
    </div>
    <label for="script-code">Script do formulário</label>
    <div style="position: relative;">
      <div class="copy-tag" @click="copyTextArea('script', script)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256"><path d="M200,32H163.74a47.92,47.92,0,0,0-71.48,0H56A16,16,0,0,0,40,48V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm-72,0a32,32,0,0,1,32,32H96A32,32,0,0,1,128,32Zm72,184H56V48H82.75A47.93,47.93,0,0,0,80,64v8a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V64a47.93,47.93,0,0,0-2.75-16H200Z"></path></svg>
        Copiar Script</div>
      <div class="form-code">
        <code id="script-code">{{ script }}</code>
      </div>
    </div>
  </BaseModal>
</template>
<script>
export default {
  data() {
    return {
      form_html: "",
      script: "",
    };
  },
  props: ["form"],
  methods: {
    copyTextArea(type, code) {
      navigator.clipboard.writeText(code)
        .then(() => {
          this.$bvToast.toast(`O ${type == 'html' ? 'HTML' : 'Script'} do formulário foi copiado com sucesso`, {
            title: "Copiado",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    buildHtml() {
      var fields = "";
        var thankYouPage = this.form.metas.find(x => x.meta_key == 'thank_you_page');
        var canRedirect = thankYouPage && thankYouPage.meta_value  && thankYouPage.meta_value != "" && thankYouPage.meta_value != "/";
      this.form.fields.forEach((field) => {
        fields += field.form_field;
      });
      fields += `<input type="hidden" id="tenant_id" value="${this.$store.getters.user.user.tenant_id}"> `;
      fields += `<input type="hidden" id="form_id" value="${this.form.id}"> `;
      var button = `<button onclick="send()" type="button" name="Cadastre-se" data-action="submit" style="height: 45px; width: 100%; border: none; text-transform: none; letter-spacing: 0.2px; font-size: 14px; background-color: rgb(0, 0, 0); color: rgb(255, 255, 255); border-radius: 10px; padding: 10px 0px; font-weight: 600; margin: 8px 0px; box-shadow: none; display: flex; align-items: center; justify-content: center;"><span data-v-c89a95f8="" class="material-icons" style="font-size: 25px; color: rgb(255, 255, 255); margin-right: 5px; display: none;"></span>
      Cadastre-se
    </button>`;
      var log = `<p data-log="infoLog" style="font-size: 14px; padding: 0; display: none;">Por favor, preencha os campos corretamente</p>
`;
      var form = `<form style=" border: 1px solid rgb(226, 226, 226); width: 100%; margin: 0 auto; padding: 20px;">${
        fields + log + button
      }</form>`;
      this.form_html = form;

      this.script = `
      <link rel="stylesheet" href="https://adm.gdigital.com.br/css/form.css">
      <script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"><\/script>
      <script>
      function send() {
      var formData = new FormData();
      var labels = document.querySelectorAll("form input");
      var textsarea = document.querySelectorAll("form textarea");
      var selects = document.querySelectorAll("form select");
      var form = document.querySelector("form");
      var checked = false;
      var checkbox_required = false;
      formData.append("tenant_id", document.querySelector("#tenant_id").value);
      formData.append("form_id", document.querySelector("#form_id").value);
      for (let i = 0; i < labels.length; i++) {
        const element = labels[i];
        let name_field = element.name.replace("-preview", "");
        if (
          element.type !== "radio" &&
          element.type !== "checkbox" &&
          element.type !== "file"
        ) {
          formData.append(name_field, element.value);
        }
        document.querySelector('[data-log="infoLog"]').style.display = "none";
        if (element.ariaRequired && element.value === "") {
          document.querySelector('[data-log="infoLog"]').style.color = "red";
          document.querySelector('[data-log="infoLog"]').style.display = "block";
          return;
        }

        checkbox_required =
          element.type === "checkbox" && element.ariaRequired ? true : false;

        if (
          (element.type === "radio" && element.checked) ||
          (element.type === "checkbox" && element.checked)
        ) {
          checked = element.type === "checkbox" ? true : false;
          formData.append(name_field, element.value);
        }
      }

      if (!checked && checkbox_required) {
        document.querySelector('[data-log="infoLog"]').style.color = "red";
        document.querySelector('[data-log="infoLog"]').style.display = "block";
        return;
      }

      document.querySelector('[data-log="infoLog"]').style.display = "none";

      for (let i = 0; i < textsarea.length; i++) {
        const element = textsarea[i];
        let name_field = element.name.replace("-preview", "");
        formData.append(name_field, element.value);
        document.querySelector('[data-log="infoLog"]').style.display = "none";
        if (element.ariaRequired && element.value === "") {
          document.querySelector('[data-log="infoLog"]').style.color = "red";
          document.querySelector('[data-log="infoLog"]').style.display = "block";
          return;
        }
      }

      for (let i = 0; i < selects.length; i++) {
        const element = selects[i];
        let name_field = element.name.replace("-preview", "");
        formData.append(name_field, element.value);
        document.querySelector('[data-log="infoLog"]').style.display = "none";
        if (element.ariaRequired && element.value === "") {
          document.querySelector('[data-log="infoLog"]').style.color = "red";
          document.querySelector('[data-log="infoLog"]').style.display = "block";
          return;
        }
      }

      const inputFiles = document.querySelectorAll('input[type="file"]');
      inputFiles.forEach((file) => {
        if (file && file.files[0]) {
          formData.append(file.name.replace("-preview", ""), file.files[0]);
        }
      });

      axios
        .post("${process.env.VUE_APP_API_HOST}" + "form/register", formData)
        .then((resp) => {
          const query_params = resp.data && resp.data.query_params ? resp.data.query_params : '';
            let url = "${thankYouPage.meta_value}"+query_params;
          let canRedirect=${canRedirect}
          if(canRedirect){
            window.location.href = url;
          }
          document.querySelector('[data-log="infoLog"]').style.color = "green";
          document.querySelector('[data-log="infoLog"]').style.display = "block";
          document.querySelector('[data-log="infoLog"]').innerText = "Lead cadastrado com sucesso";
        })
        .catch((error) => {
          document.querySelector('[data-log="infoLog"]').style.color = "red";
          document.querySelector('[data-log="infoLog"]').style.display = "block";
          document.querySelector('[data-log="infoLog"]').innerText = "Por favor, preencha os campos corretamente";

        });
      } 
      <\/script>
      `;
    },
  },
};
</script>

<style scoped lang="scss">
.form-code {
  background-color: var(--white-light);
  padding: 20px;
  border-radius: 10px 0 10px 10px;
  max-height: 200px;
  overflow: hidden auto;
  position: relative;
  color: var(--gray02);
  code {
    color: var(--gray02);
  }
}
.copy-tag {
  height: 30px;
  font-size: 13px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 0 10px;
  color: var(--greenn);
  background-color: var(--greenn-transparent);
  border-radius: 10px 10px 0 0;
  position: absolute;
  top: -30px;
  right: 0;
  cursor: pointer;
  transition: .3s;
  >svg {
    fill: var(--greenn);
  }
  &:hover {
    background-color: var(--greenn2)
  }
}
::-webkit-scrollbar-track {
  background: transparent;
}
</style>
